import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '0x1409565126527460336C359c9734415eAC7b7528',
      56: '0x3DE53f9C4F6884a0474f527FCd2164af0Bb78A80',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '0xDFc7181eA37e64de135F73ca90070581bd04a873',
      56: '0x3b8bEC7d12c4749Cdc438d5dE2d84Dd9F71f4F2F',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '0xA73963ef953a5aEE4858D75577e9905d41614092',
      56: '0x1311f9a348e2e4F959848Ebcbd7676AE5Bb8e713',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 3,
    lpSymbol: 'MON-BNB LP',
    lpAddresses: {
      97: '0xA73963ef953a5aEE4858D75577e9905d41614092',
      56: '0x4aa5f6721B1c9449861858D2E60d9EEE7dc20193',
    },
    token: serializedTokens.monnfts,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 4,
    lpSymbol: 'MON-CAKE LP',
    lpAddresses: {
      97: '0xA73963ef953a5aEE4858D75577e9905d41614092',
      56: '0x5852698259D860AD9796a4f57909cdd08C9eF2A1',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.monnfts,
  },
  
  
]

export default farms
