import { Token, ChainId } from '@pancakeswap/sdk'
import tokens from './tokens'
import farms from './farms'
import { Ifo } from './types'

const chainID = process.env.REACT_APP_CHAIN_ID === "97" ? ChainId.TESTNET : ChainId.MAINNET;
const cakeBnbLpToken = new Token(chainID, farms[2].lpAddresses[chainID], 18, farms[2].lpSymbol)

const ifos: Ifo[] = [
  
]

export default ifos
