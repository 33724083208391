import { Currency, ETHER, Token } from '@pancakeswap/sdk'
import { BinanceIcon } from '@pancakeswap/uikit'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'
import pancakeDefaultToken from "../../config/constants/tokenLists/pancake-default.tokenlist.json"

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      
      for (let i = 0; i < pancakeDefaultToken.tokens.length; i++) {
        if (currency.address === pancakeDefaultToken.tokens[i].address){
          return [pancakeDefaultToken.tokens[i].logoURI]
        }
      }

      return [getTokenLogoURL(currency.address)]

    }
    

    return []
  }, [currency, uriLocations])

 

  if (currency === ETHER) {
    return <BinanceIcon width={size} style={style} />
  }

 

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
