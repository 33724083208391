import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '0xDa80e30f09CAb93d7D9f9812AbaAE3332E9e1d58',
      56: '0x2248A4Ea49f98F1BC70277821134A199462201aA',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 2,
    stakingToken: serializedTokens.monnfts,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '0x6719A6E31Cb45c6a2a1a745ff5376eC0A18C9373',
      56: '0xcaCe546819C51628743A7AAdb5721d6ABef6CE2C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 2,
    tokenPerBlock: '0.1',
  },
  {
    sousId: 3,
    stakingToken: serializedTokens.monnfts,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '0x6719A6E31Cb45c6a2a1a745ff5376eC0A18C9373',
      56: '0x247Bd1593d8335CF0d3e24878efa46cBBb2e7A34',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 3,
    tokenPerBlock: '0.1',
  },
 
  
]

export default pools
